import REQ from '@/app/REQ'
import {wordViewUrl} from "@/doc/consts"

export default {
    name: 'docTplt',
    props:['id'],

    data() {
        return {
            wordViewUrl,

            docTplt: {},
            fileUrl: '',
        }
    },

    created() {
        this.getDocTplt()
    },

    methods: {
        getDocTplt() {
            this.req2Data(REQ.docTplt, {id:this.id},'docTplt', ()=>this.fileUrl = wordViewUrl + encodeURIComponent(this.docTplt.file))
        },

    },

}
